$(() => {
  const intCurrentPage = parseInt(currentPage || 1, 10);
  const intTotalPages = parseInt(totalPages || 0, 10);
  if (intTotalPages >= 2) {
    $('#pagination').bootstrapPaginator({
      bootstrapMajorVersion: 3,
      currentPage: intCurrentPage,
      numberOfPages: 6,
      totalPages: intTotalPages,
      onPageClicked: (event, originalEvent, type, page) => {
        updateQueryStringParam('page', page);
      },
    });
  }
});
